import logo from './logo.svg';
import './App.css';
import React, { Component } from "react";
import ChatBot from "@optimlx/villachat";

function App() {
  return (
    <div className="App">
      
        
        <><><div align="left">
         </div><ChatBot message={{client_id:"100001"}}/></></>
      
    </div>
  );
}

export default App;
